import React, {useState, createContext, useEffect} from 'react'
import { useQuery, gql } from '@apollo/client'

const ApolloContext = createContext(false)

function ApolloContextProvider({children, location}) {
  // State for telling if the Apollo is active or not
	const [isLoading, setLoading] = useState(false)
	const [data, setData] = useState()

  const [nextQuery, setNextQuery] = useState(false)

  // Apollo navi query
  const { loading, error, data: apollo, fetchMore } = useQuery(APOLLO_QUERY, {
    variables: { after: null },
    notifyOnNetworkStatusChange: true,
  })


  useEffect(() => {
		setLoading(loading)
		// If loaded rehydrate
		if(!loading && !error) {
			setData(apollo)
		}
  },[loading, error, nextQuery])


  const loadMore = () => {
    const { endCursor, hasNextPage } = data?.News.pageInfo
  
    if(hasNextPage) {
      nextQuery ? setNextQuery(false) : setNextQuery(true)
      fetchMore({
        variables: {after: endCursor},
        updateQuery: (prevResult, { fetchMoreResult }) => {
    
          fetchMoreResult.News.nodes = [
            ...prevResult.News.nodes,
            ...fetchMoreResult.News.nodes
          ];
          return fetchMoreResult;
        }
      })
    }
 
  }

	return (
		<ApolloContext.Provider value={{
      isLoading,
			data,
      loadMore
    }}>
      {children}
    </ApolloContext.Provider>
	)
}

export default ApolloContext
export { ApolloContextProvider }

// WPGRAPHQL Query for rehydration, posts
const APOLLO_QUERY = gql`
query apolloQuery($after: String)
{
  academics: academics(first: 35) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
        }
      }
      pageBuilder {
        fieldGroupName
        sections {
          ... on Academic_Pagebuilder_Sections_Content {
            anchor
            classes
            block {
              ... on Academic_Pagebuilder_Sections_Content_Block_Wysiwyg {
                fieldGroupName
                anchor
                classes
                wysiwyg
              }
              ... on Academic_Pagebuilder_Sections_Content_Block_Custom {
                fieldGroupName
                anchor
                classes
                asset {
                  ... on Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg {
                    fieldGroupName
                    assetId
                    assetWysiwyg
                  }
                  ... on Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Field {
                    fieldGroupName
                    assetField
                    assetId
                  }
                }
              }
              ... on Academic_Pagebuilder_Sections_Content_Block_Video {
                fieldGroupName
                anchor
                bgOverlay
              }
              ... on Academic_Pagebuilder_Sections_Content_Block_Slide {
                fieldGroupName
                anchor
                bgOverlay
              }
              ... on Academic_Pagebuilder_Sections_Content_Block_Image {
                fieldGroupName
                anchor
                classes
                bgOverlay
                parallaxFrom
                parallaxTo
                wysiwyg
                img {
                  srcSet
                  altText
                }
                imgResponsive {
                  srcSet
                  altText
                }
              }
            }
          }
        }
      }
    } 
  }
  degrees: schools {
    nodes {
      name
      slug
      academics(first: 35) {
        nodes {
          id
          slug
          title(format: RENDERED)
          uri
          status
          content(format: RENDERED)
          excerpt(format: RENDERED)
          date
          featuredImage {
            node {
              id
              srcSet
            }
          }
          author {
            node {
              name
              slug
            }
          }
          categories(where: {order: DESC}) {
            nodes {
              name
              slug
            }
          }
          pageBuilder {
            fieldGroupName
            sections {
              ... on Academic_Pagebuilder_Sections_Content {
                anchor
                classes
                block {
                  ... on Academic_Pagebuilder_Sections_Content_Block_Wysiwyg {
                    fieldGroupName
                    anchor
                    classes
                    wysiwyg
                  }
                  ... on Academic_Pagebuilder_Sections_Content_Block_Custom {
                    fieldGroupName
                    anchor
                    classes
                    asset {
                      ... on Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg {
                        fieldGroupName
                        assetId
                        assetWysiwyg
                      }
                      ... on Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Field {
                        fieldGroupName
                        assetField
                        assetId
                      }
                    }
                  }
                  ... on Academic_Pagebuilder_Sections_Content_Block_Video {
                    fieldGroupName
                    anchor
                    bgOverlay
                  }
                  ... on Academic_Pagebuilder_Sections_Content_Block_Slide {
                    fieldGroupName
                    anchor
                    bgOverlay
                  }
                  ... on Academic_Pagebuilder_Sections_Content_Block_Image {
                    fieldGroupName
                    anchor
                    classes
                    bgOverlay
                    parallaxFrom
                    parallaxTo
                    wysiwyg
                    img {
                      srcSet
                      altText
                    }
                    imgResponsive {
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        } 
      }
    }
  }
  sonhs: posts(where: {categoryName: "SONHS"}, first: 4) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  nursing: posts(where: {categoryName: "Nursing"}, first: 5) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  campusLife: posts(where: {categoryName: "Student Life"}, first: 100) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  studentStories: posts(where: {categoryName: "Student stories"}, first: 2) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  News: posts(where: {categoryName: "News"}, first: 100, after: $after) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  events: posts(where: {categoryName: "Events"}) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  messages: posts(where: {categoryName: "Message"}) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
  }
  testimonials: posts(where: {categoryName: "testimonials"}, first: 4, after: $after) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      post_image {
        field
        postImage {
          srcSet
        }
        heroImage {
          srcSet
        }
      }
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories(where: {order: DESC}) {
        nodes {
          name
          slug
          category_image {
            categoryImage {
              srcSet
            }
          }
        }
      }
    } 
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`


// add_filter( 'register_post_type_args', function( $args, $post_type ) {

//   // Change this to the post type you are adding support for
// if ( 'academic' === $post_type ) { 
// $args['show_in_graphql'] = true;
// $args['graphql_single_name'] = 'academic';
// $args['graphql_plural_name'] = 'academics';
// }

// return $args;

// }, 10, 2 );
// add_filter( 'register_taxonomy_args', function( $args, $taxonomy ) {

//   // Change this to the post type you are adding support for
// if ( 'schools' === $taxonomy ) { 
// $args['show_in_graphql'] = true;
// $args['graphql_single_name'] = 'school';
// $args['graphql_plural_name'] = 'schools';
// }

// return $args;

// }, 10, 2 );
import React, { useContext } from 'react'
import '../EVG/css/_core.scss';

import Header from './Header'

import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'


import FsLightbox from 'fslightbox-react'
import FSLightBoxContext from '../context/FSLightBoxContext'

if (typeof window !== 'undefined') {

  // Set up pace js
  window.paceOptions = {
    className: 'dss-loader',
    ajax: false, // ajax monitoring - disabled
    restartOnPushState: false, // no restart on push state
    document: false, // document ready state monitoring - disabled
    eventLag: false, // monitoring of event loop lag, signaling js is being executed - disabled
    elements: {
      selectors: ['.hero', '.main-frontpage'] // looks for existance of this element
    }
  }

  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]:not([href="#"])', {
    speed: 800,
    speedAsDuration: true,
    easing: 'easeInOutCubic',
    offset: window.innerHeight * 0.5
  })
}

function Layout(props) {
  const lightBoxContext = useContext(FSLightBoxContext)

  return (
    <>

      <Helmet>
        <script src="/js/pace/pace.min.js"></script>

        <script type="text/javascript">{
          `adroll_adv_id = "XMPFT7VEHBEBXKHA432V24";
						adroll_pix_id = "BIWODYDE4FHVJOE5HDNNX5";
						adroll_version = "2.0";
						(function(w, d, e, o, a) {
								w.__adroll_loaded = true;
								w.adroll = w.adroll || [];
								w.adroll.f = [ 'setProperties', 'identify', 'track' ];
								var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
												+ "/roundtrip.js";
								for (a = 0; a < w.adroll.f.length; a++) {
										w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
												return function() {
														w.adroll.push([ n, arguments ])
												}
										})(w.adroll.f[a])
								}
								e = d.createElement('script');
								o = d.getElementsByTagName('script')[0];
								e.async = 1;
								e.src = roundtripUrl;
								o.parentNode.insertBefore(e, o);
						})(window, document);
						adroll.track("pageView");`
        }
        </script>

        <script type="text/javascript">{
          `<!-- Meta Pixel Code -->
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2597232040286922');
          fbq('track', 'PageView');
          <!-- End Meta Pixel Code -->`
        }
        </script>

        <script type="text/javascript">{
          `_linkedin_partner_id = "4670212";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);`
        }
        </script>

        <script type="text/javascript">{
          `(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`
        }
        </script>

      </Helmet>

      <Header {...props.data} />

      <ParallaxProvider>
        {props.children}
      </ParallaxProvider>
      {lightBoxContext.sources ?
        <FsLightbox
          toggler={lightBoxContext.toggler}
          sources={lightBoxContext.sources}
          slide={lightBoxContext.slide}
          key={lightBoxContext.sources[0]}
          captions={lightBoxContext.captions}
          type="image"
        />
        :
        null}
    </>
  )
}

export default Layout
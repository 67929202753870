import React from 'react'
import './style.scss'
//import { Transition, TransitionGroup } from 'react-transition-group'
//import anime from 'animejs'

import NaviPrimary from './Primary'

function Navi(props) {


  return (
		<>
			<NaviPrimary { ...props } />
		</>
  )
}

export default Navi
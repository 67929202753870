import fetch from 'isomorphic-fetch'
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client'

const client = new ApolloClient({
	link: createHttpLink({
		uri: process.env.GATSBY_WP_URL + '/graphql',
		fetch,
	}),
	cache: new InMemoryCache()
})

export default client
import React from 'react'
import './style.scss'
// import NaviContext from '../../context/NaviContext'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

function SocialIcons(props) {
	//const naviContext = useContext(NaviContext)
	
  return (
		<>
			<div className='social-icons font1'>
				<div className='social-icon hint--rounded hint--top' data-hint='@miamiregionaluniversity'>
					<a href='https://www.facebook.com/miamiregionaluniversity/' target='_blank' rel='noopener noreferrer'>
						FaceBook
					</a>
				</div>
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='@designsentry'>
					<a href='https://twitter.com/designsentry' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faTwitter} />
					</a>
				</div> */}
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='YouTube'>
					<a href='https://youtube.com' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faYoutube} />
					</a>
				</div> */}
				<div className='social-icon hint--rounded hint--top' data-hint='@miamiregionaluniversity'>
					<a href='https://www.instagram.com/miamiregionaluniversity/?igshid=caquoamzbesu' target='_blank' rel='noopener noreferrer'>
						Instagram
					</a>
				</div>
				<div className='social-icon hint--rounded hint--top' data-hint='@mru'>
					<a href='https://www.youtube.com/channel/UCvWBAGboih-HH4uF4DYseJg' target='_blank' rel='noopener noreferrer'>
						YouTube
					</a>
				</div>
			</div>
		</>
  )
}

export default SocialIcons